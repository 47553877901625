import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

// Функция для получения категории по ID
const fetchCategoryById = async (id) => {
  const response = await fetch(
    `https://guangda.sg/wordpress/wp-json/wc/v3/products/categories/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa("ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8:cs_aadeb7cee84c6e16dd9ba57804e912d40c281740"),
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching category");
  }

  return response.json();
};

// Функция для получения продукта по ID
const fetchProductById = async (id) => {
  const response = await fetch(
    `https://guangda.sg/wordpress/wp-json/wc/v3/products/${id}`,
    {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa("ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8:cs_aadeb7cee84c6e16dd9ba57804e912d40c281740"),
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    throw new Error("Error fetching product");
  }

  return response.json();
};

const Breadcrumb = () => {
  const [breadcrumbCategories, setBreadcrumbCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { id, productId } = useParams(); // id - категория, productId - продукт

  useEffect(() => {
    const fetchCategoryHierarchy = async (categoryId) => {
      let categories = [];
      let currentCategoryId = categoryId;

      // Получаем иерархию категорий
      while (currentCategoryId !== 0) {
        const category = await fetchCategoryById(currentCategoryId);
        categories.unshift(category); // Добавляем категорию в начало списка
        currentCategoryId = category.parent || 0;
      }

      setBreadcrumbCategories(categories);
      setLoading(false);
    };

    const loadBreadcrumbs = async () => {
      setLoading(true);
      const pathnames = location.pathname.split("/").filter((x) => x);

      if (pathnames.includes("product") && productId) {
        // Если это страница продукта, загружаем информацию о продукте
        const productData = await fetchProductById(productId);
        setProductName(productData.name); // Устанавливаем название продукта
        if (productData.categories && productData.categories.length > 0) {
          await fetchCategoryHierarchy(productData.categories[0].id); // Загружаем иерархию категорий продукта
        }
      } else if (pathnames.includes("category") && id) {
        // Если это страница категории, загружаем иерархию категорий
        await fetchCategoryHierarchy(parseInt(id, 10));
      } else {
        setLoading(false);
      }
    };

    loadBreadcrumbs();
  }, [location, id, productId]);

  const generateBreadcrumbs = () => {
    let breadcrumbs = [
      <span key="home">
        <Link to="/">Home</Link> /
        <Link to="/category/">Category</Link> /  
      </span>
    ];
  
    breadcrumbCategories.forEach((category, index) => {
      breadcrumbs.push(
        <span key={category.id}>
          <Link to={`/category/${category.id}`}>
            {loading ? '...' : category.name}
          </Link>
          {index < breadcrumbCategories.length - 1 && " / "}
        </span>
      );
    });
  
    if (location.pathname.includes("product") && productName) {
      breadcrumbs.push(
        <span key="product">
          {" / "} {/* Добавляем разделитель перед названием товара */}
          {loading ? '...' : productName}
        </span>
      );
    }
  
    return breadcrumbs;
  };
  

  return (
    <div className="breadcrumbs mb-4">
      {generateBreadcrumbs()}
    </div>
  );
};

export default Breadcrumb;
