import React, { useState } from "react";
import Navbar from "./common/Navbar";
import logo from "../assets/cropped-Guangda_logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import arrowSearc from "../images/magnifier.png";
const Header = () => {


  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleSearchVisibility = () => {
    setIsSearchVisible(!isSearchVisible);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search?term=${searchQuery}`);
    }
  };

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  
  return (
    <header className="flex justify-between items-center p-4 container m-auto">
      <div className="flex items-center">
        <Link to="/">
          <img src={logo} alt="logo" className="h-12 w-auto mr-2" />
        </Link>
      </div>

      

      
      <Navbar color="text-black" showSubMenu={true} open={openMobileMenu}/>

      <div className={`hidden md:flex items-center gap-2`}>
            <input 
              type="text" 
              placeholder="Search..." 
              className="border-red-600 border-2 rounded p-2"
              value={searchQuery} 
              onChange={handleInputChange}
              onKeyPress={handleKeyPress} 
            />
            <span className="w-[20px]">
              <Link to={`/search?term=${searchQuery}`}>
                <img src={arrowSearc} alt="Search Icon" />
              </Link>
            </span>
          </div>


          

      <button className="space-y-2 block md:hidden " onClick={() => setOpenMobileMenu(!openMobileMenu)}>
          <span className="block w-8 h-1 bg-gray-600"></span>
          <span className="block w-8 h-1 bg-gray-600"></span>
          <span className="block w-8 h-1 bg-gray-600"></span>
      </button>
    </header>
  );
};

export default Header;
