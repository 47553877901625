import Hero from "./components/Hero";
import HomeProductsSlider from "./components/home-products-slider";
import resantaImage from "../images/resanta.jpg";
import huterImage from "../images/huter.png";
import euroluxImage from "../images/eurolux.jpg";

const Home = () => {
  return (
    <>
      <Hero />
      <HomeProductsSlider
        id="17"
        title="Resanta"
        backgroundImage={resantaImage}
      />
      <HomeProductsSlider id="18" title="Huter" backgroundImage={huterImage} />
      <HomeProductsSlider
        id="200"
        title="Eurolux"
        backgroundImage={euroluxImage}
      />
    </>
  );
};

export default Home;
