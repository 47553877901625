import React from "react";
import { Link } from "react-router-dom";

const DropdownMenu = ({ subcategories }) => {
  return (
    <div className="absolute bg-white shadow-md mt-[-2px] transition duration-1000 w-max z-50">
      <ul className="p-2">
        {subcategories.map((subcategory) => (
          <li key={subcategory.id} className="p-2 hover:bg-gray-200">
            <Link to={`/category/${subcategory.id}`}>{subcategory.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenu;
