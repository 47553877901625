import { useState } from "react";

const ContactsPage = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Предотвращаем стандартное поведение формы

    // Здесь можно добавить отправку данных на сервер
    const formData = new FormData(event.target);

    // Например, отправка через fetch
    fetch("/send.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setIsPopupVisible(true); // Показываем попап при успешной отправке
        } else {
          alert("Failed to send message. Please try again.");
        }
      })
      .catch(() => {
        alert("An error occurred while sending the message.");
      });
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false); // Закрываем попап
  };

  return (
    <div>
      <div className="mt-[0px]">
        {/* Заголовок страницы */}
        <div className="text-center mb-8">
          <h2 className="text-[42px] text-[#333] leading-[1.3] font-normal font-montserrat">
            Contacts
          </h2>
        </div>

        {/* Карта Google */}
        <div className="mb-8">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63820.95546778049!2d103.77077686704084!3d1.2883399617712183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1b8a605c23d1%3A0x6eaa1b16118c2a2a!2sBryton%20House!5e0!3m2!1sru!2sru!4v1690444117089!5m2!1sen!2sen"
            width="100%"
            height="450"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            aria-hidden="false"
            tabIndex="0"
            title="Google Maps"
          ></iframe>
        </div>

        {/* Информационный блок */}
        <div className="mb-8 text-left max-w-[1350px] mx-auto">
          <p>
          For more information about our privacy policies or if you have any questions, please do not hesitate to contact us at the email address below. If you would like to file a complaint, we would be happy to assist you.
          </p>
        </div>

        {/* Блок с контактной информацией и формой */}
        <div className="flex flex-wrap mt-10 max-w-[1350px] mx-auto mb-10">
          {/* Контактная информация */}
          <div className="w-full md:w-1/2 text-left">
            <div className="font-bold mb-2">Guangda</div>
            <ul className="list-disc pl-5">
              <li>
                Address: 12, Hoy Fatt Road, Bryton House, #06-00 room 13, 159506
              </li>
              <li>
                Tel:{" "}
                <a
                  href="tel:+6583559501"
                  className="text-[#4782d3] hover:text-[#252525]"
                >
                  +65-83559501
                </a>
              </li>
              <li>
                E-Mail:{" "}
                <a
                  href="mailto:sales@guangda.sg"
                  className="text-[#4782d3] hover:text-[#252525]"
                >
                  sales@guangda.sg
                </a>
              </li>
              <li>
                <strong>GUANGDA SHANGWU CO., PTE. LTD</strong>
              </li>
            </ul>
          </div>

          {/* Форма обратной связи */}
          <div className="w-full md:w-1/2 mt-8 md:mt-0">
            <form className="w-full" onSubmit={handleSubmit}>
              <p className="mb-4">
                <label className="block mb-2">
                  Name*
                  <input
                    type="text"
                    name="your-name"
                    className="w-full h-10 px-3 bg-[#f2f2f2] border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                    maxLength="80"
                  />
                </label>
              </p>
              <p className="mb-4">
                <label className="block mb-2">
                  E-mail*
                  <input
                    type="email"
                    name="your-email"
                    className="w-full h-10 px-3 bg-[#f2f2f2] border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                    maxLength="80"
                  />
                </label>
              </p>
              <p className="mb-4">
                <label className="block mb-2">
                  Mobile phone*
                  <input
                    type="tel"
                    name="tel-722"
                    className="w-full h-10 px-3 bg-[#f2f2f2] border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600"
                    required
                    placeholder="+65-________"
                    pattern="\+65-\d{8}"
                  />
                </label>
              </p>
              <p className="mb-4">
                <label className="block mb-2">
                  Message
                  <textarea
                    name="your-message"
                    className="w-full h-40 px-3 py-2 bg-[#f2f2f2] border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-600 resize-none"
                    maxLength="400"
                  ></textarea>
                </label>
              </p>
              <p>
                <input
                  type="submit"
                  value="Send"
                  className="bg-[#4782d3] text-white text-[16px] px-6 py-3 hover:bg-[#252525] transition duration-300 cursor-pointer"
                />
              </p>
            </form>
          </div>
        </div>
      </div>

      {/* Попап с сообщением */}
      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-lg text-center">
            <p className="mb-4">Thank you for your message!</p>
            <button
              onClick={handleClosePopup}
              className="bg-[#4782d3] text-white px-4 py-2 rounded hover:bg-[#252525] transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactsPage;
