import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import ContentLoader from "react-content-loader";
//import Breadcrumbs from "../Breadcrumb"; // Убедитесь, что Breadcrumbs принимает категории
import { motion } from "framer-motion";
import Breadcrumb from '../../components/Breadcrumb';

const ProductPage = () => {
    const [productData, setProductData] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const { productId } = useParams();
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const location = useLocation();  // Получаем объект location
    const currentPage = location.state?.currentPage || 1;
    const idCat = location.state?.id || null;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        // Получение данных о продукте
        fetch(`https://guangda.sg/wordpress/wp-json/wc/v3/products/${productId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Basic ' + btoa('ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8:cs_aadeb7cee84c6e16dd9ba57804e912d40c281740'),
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setProductData(data);
            setCurrentImageIndex(0);
            if (data.categories && data.categories.length > 0) {
                return data.categories[0].id;
            } else {
                return null;
            }
        })
        .then(categoryId => {
            // Получение данных о похожих продуктах
            fetch(`https://guangda.sg/wordpress/wp-json/wc/v3/products?category=${categoryId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Basic ' + btoa('ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8:cs_aadeb7cee84c6e16dd9ba57804e912d40c281740'),
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                const filteredProducts = data.filter(product => product.id !== parseInt(productId));
                const randomProducts = filteredProducts.sort(() => 0.5 - Math.random()).slice(0, 4);
                setSimilarProducts(randomProducts);
            })
            .catch(error => {
                console.error('Error fetching similar products:', error);
            });
        })
        .catch(error => {
            console.error('Error fetching product data:', error);
        })
        .finally(() => setLoading(false));
    }, [productId]);

    const handlePrevImage = () => {
        const newIndex = currentImageIndex === 0 ? productData.images.length - 1 : currentImageIndex - 1;
        setCurrentImageIndex(newIndex);
    };

    const handleNextImage = () => {
        const newIndex = currentImageIndex === productData.images.length - 1 ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(newIndex);
    };

    const SkelArray = [
        <ContentLoader
          key="loader"
          speed={2}
          width={"100%"}
          height={408}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
        </ContentLoader>
    ];

    const verstka = productData && (
        <>
            
            <div className="max-w-5xl mx-auto">
                <Breadcrumb />

                <div className="text-center mb-8">
                    {productData.categories && productData.categories.length > 0 && (
                        <span className="text-xl text-gray-500">{productData.categories[0].name}</span>
                    )}
                    <h1 className="text-3xl font-bold">{productData.name}</h1>
                </div>

                <div className="flex items-center md:space-x-6 w-full justify-center flex-col">
                    <div className="relative mx-auto w-full max-w-[450px]">
                        <img 
                            src={productData.images[currentImageIndex]?.src} 
                            alt={productData.name} 
                            className="rounded-lg shadow-lg w-full" 
                        />
                        {productData.images.length > 1 && (
                            <>
                                <button className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full" onClick={handlePrevImage}>&lt;</button>
                                <button className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full" onClick={handleNextImage}>&gt;</button>
                            </>
                        )}
                    </div>

                    <div className="mt-6 md:mt-0 md:w-1/2 space-y-4">
                        

                        {productData.images.length > 1 && (
                            <div className="flex space-x-4">
                                {productData.images.map((image, index) => (
                                    <img 
                                        key={index} 
                                        src={image.src} 
                                        alt={productData.name} 
                                        className={`w-20 h-20 object-cover cursor-pointer rounded ${index === currentImageIndex ? 'border-2 border-red-600' : 'border'}`}
                                        onClick={() => setCurrentImageIndex(index)}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="mt-8">
                    <h2 className="text-2xl font-semibold mb-4">Characteristics</h2>
                    <div dangerouslySetInnerHTML={{ __html: productData.description }} className="text-gray-700 w-[100%] md:w-auto overflow-x-auto text-xl productTable"></div>
                </div>

                <div className="mt-8">
                    <h2 className="text-2xl font-semibold mb-4">Similar Products</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
                        {similarProducts.map(product => (
                            <motion.div 
                                whileHover={{ y: -10 }} 
                                className="shadow-lg rounded-lg overflow-hidden"
                                key={product.id}
                            >
                                <Link to={`/product/${product.id}`} className="block">
                                    <img src={product.images[0]?.src} alt={product.name} className="w-full h-48 object-contain" />
                                    <div className="p-4">
                                        <h3 className="font-semibold text-lg">{product.name}</h3>
                                    </div>
                                </Link>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div className="container mx-auto p-4">
            {loading ? SkelArray : verstka}
        </div>
    );
}

export default ProductPage;
