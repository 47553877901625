import HeroImage from "../../images/hero.jpg";

const Hero = () => {
  return (
    <div
      className="bg-cover bg-center h-[70vh]"
      style={{ backgroundImage: `url(${HeroImage})` }}
    ></div>
  );
};

export default Hero;
