import "./styles/App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
// import Resanta from "./views/Resanta";
// import Huter from "./views/Huter";
// import Eurolux from "./views/Eurolux";
import About from "./views/AboutUsPage";
import Contacts from "./views/ContactsPage";
import CatalogPage from "./views/CatalogPage";
import ProductPage from "./views/ProductPage";
import SearchResults from "./views/SearchResult";
// import Contacts from "./views/Contacts";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/category/:id" element={<CatalogPage />} />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/search" element={<SearchResults />} />
        {/* 
        <Route path="/huter" element={<Huter />} />
        <Route path="/eurolux" element={<Eurolux />} />
         */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
