import { createElement } from "react";
import { useState } from "react";



const ProductCard = ({catName, Name, image, descr}) => {


	return(
		<li class="max-w-[280px] w-full list-none flex flex-col h-full items-start justify-between">
                    <div class="block">
                        <img src={image}  class="w-full h-72 object-contain mb-4" />
                        <h2 class="text-lg font-medium text-gray-800">{Name}</h2>
                    </div>
            <span class="mt-4 inline-block bg-gray-200 text-gray-600 font-bold py-2 px-4 rounded hover:bg-gray-300 transition duration-200" >Read more</span>
        </li>
	)
}

export default ProductCard;