import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import ProductCard from "../CatalogPage/ProductCard";

const SearchResults = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProd, setTotalProd] = useState(0);
  const [loading, setLoading] = useState(true);
  const productsPerPage = 20;
  const searchQuery = new URLSearchParams(location.search).get("term");
  const [pageChanging, setPageChanging] = useState(false);
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const urlPlus = searchQuery ? `&search=${searchQuery}` : "";

      try {
        const response = await fetch(
          `https://guangda.sg/wordpress/wp-json/wc/v3/products?per_page=${productsPerPage}&page=${currentPage}${urlPlus}`,
          {
            method: "GET",
            headers: {
              Authorization:
                "Basic " +
                btoa(
                  "ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8:cs_aadeb7cee84c6e16dd9ba57804e912d40c281740"
                ),
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Продукты не найдены");
        }

        const total = response.headers.get("X-Wp-Total");
        setTotalProd(total);

        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error("Не удалось загрузить продукты:", error);
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, currentPage]);

  const handlePageChange = (pageNumber) => {
    setPageChanging(true);
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setPageChanging(true);
      setCurrentPage((prevPage) => prevPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setPageChanging(true);
      setCurrentPage((prevPage) => prevPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const totalPages = Math.ceil(totalProd / productsPerPage);

  const generatePagination = () => {
    const pages = [];
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, 5, "...");
      } else if (currentPage > totalPages - 3) {
        pages.push(
          "...",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pages.push(
          "...",
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
          "..."
        );
      }
    }

    return pages;
  };

  const SkelArray = [
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={1}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={2}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
    <ContentLoader
      speed={2}
      width={"100%"}
      height={408}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      key={3}
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="408" />
    </ContentLoader>,
  ];

  const ProdArray = products.map((item) => (
    <Link
      to={{ pathname: `/product/${item.id}` }}
      state={{ currentPage }}
      key={item.id}
      className="w-full max-w-[285px]"
    >
      <ProductCard
        catName={
          item.categories && item.categories[0]
            ? item.categories[0].name
            : "No Category"
        }
        Name={item.name}
        image={
          item.images && item.images[0] ? item.images[0].src : "placeholder.jpg"
        }
        descr={item.description ? item.description : "No description available"}
      />
    </Link>
  ));

  return (
      <div className="max-w-[1200px] mx-auto flex flex-row flex-wrap flex-grow gap-[20px] SearchPage my-10">
        <div className="container">
          <h1 className="text-2xl font-bold mb-4 text-center">Search results for "{searchQuery}"</h1>
          <div className="producCards justify-center flex flex-row flex-wrap align-middle md:justify-left  gap-[20px]">
            {/* <Breadcrumbs /> */}
            {loading || pageChanging ? SkelArray : ProdArray}
          </div>
        </div>
        <div className="pagination flex gap-[15px] mx-auto">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Prev
          </button>
          {generatePagination().map((page, index) =>
            page === "..." ? (
              <span key={index} className="pagination-ellipsis"></span>
            ) : (
              <button
                key={index}
                onClick={() => handlePageChange(page)}
                className={`pagination-button font-bold text-gray-600 py-2 px-4 rounded  border-2 border-gray-600
                  ${
                  page === currentPage ? "bg-gray-200" : "bg-white"
                }`}
              >
                {page}
              </button>
            )
          )}
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
  );
};

export default SearchResults;
