import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import DropdownMenu from "../DropdownMenu";

const Navbar = ({ color, showSubMenu = false, open }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [resantaSubcategories, setResantaSubcategories] = useState([]);
  const [huterSubcategories, setHuterSubcategories] = useState([]);

  const handleMouseEnter = (menu) => {
    setActiveMenu(menu);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  useEffect(() => {
    const fetchSubcategories = async (categoryId, setSubcategories) => {
      const baseUrl = "https://guangda.sg/wordpress/wp-json/wc/v3/products/categories";
      const consumerKey = "ck_ebcb9d1ddb3bdaea006cde71dc2a00b812a41dd8";
      const consumerSecret = "cs_aadeb7cee84c6e16dd9ba57804e912d40c281740";
      const queryString = new URLSearchParams({
        parent: categoryId,
        _fields: "id,slug,name,parent",
        consumer_key: consumerKey,
        consumer_secret: consumerSecret,
      }).toString();

      try {
        const response = await fetch(`${baseUrl}?${queryString}`);
        if (!response.ok) {
          throw new Error("Subcategories not found");
        }

        const categories = await response.json();
        setSubcategories(categories);
      } catch (error) {
        console.error("Failed to load subcategories:", error);
      }
    };

    fetchSubcategories(17, setResantaSubcategories);
    fetchSubcategories(18, setHuterSubcategories);
  }, []);

  return (
    <nav className={`absolute left-0 top-[80px] md:top-0 bg-white md:bg-transparent w-full md:w-auto md:relative ${open ? "maxheight" : "hideheight"} md:block md:border-b-0`}>
      <ul className={`flex gap-4 text-center md:text-left space-x-6 ${color} w-fit text-lg mx-auto md:mx-0 flex-col md:flex-row font-medium`}>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            HOME
          </NavLink>
        </li>
        <li
          onMouseEnter={() => handleMouseEnter("resanta")}
          onMouseLeave={handleMouseLeave}
          className="relative"
        >
          <NavLink
            to="category/17"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            RESANTA
          </NavLink>
          {activeMenu === "resanta" && <DropdownMenu subcategories={resantaSubcategories} />}
        </li>
        <li
          onMouseEnter={() => handleMouseEnter("huter")}
          onMouseLeave={handleMouseLeave}
          className="relative"
        >
          <NavLink
            to="category/18"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            HUTER
          </NavLink>
          {activeMenu === "huter" && <DropdownMenu subcategories={huterSubcategories} />}
        </li>
        <li>
          <NavLink
            to="category/200"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            EUROLUX
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            ABOUT COMPANY
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contacts"
            className={({ isActive }) => (isActive ? "border-black" : "")}
          >
            CONTACTS
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
